import logo from "./logo-glitch.gif";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Hazey Jane Films logo" />
        <p>
          Award winning Film
          <br /> & TV production company
        </p>
        <a
          href="https://www.bifa.film/film/sweetheart/"
          target="__blank"
          rel="noreferrer"
        >
          Debut feature, SWEETHEART winner of <br />
          TWO British Independent Film Awards
        </a>
        <div className="icons">
          <a
            href="https://www.facebook.com/hazeyjanefilms"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon fa-brands fa-facebook-f"></i>
          </a>
          <a
            href="https://twitter.com/hazeyjanefilms"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon fa-brands fa-twitter"></i>
          </a>
          <a
            href="https://www.imdb.com/title/tt10919362/?ref_=adv_li_tt"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon fa-brands fa-imdb"></i>
          </a>
          <a
            href="https://vimeo.com/user64056579"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon fa-brands fa-vimeo-v"></i>
          </a>
          <a
            href="https://www.instagram.com/hazeyjanefilms/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon fa-brands fa-instagram"></i>
          </a>
        </div>
        <a href="mailto:info@hazeyjanefilms.com">info@hazeyjanefilms.com </a>
        <p>Unfortunately we do not accept unsolicited scripts.</p>
        <div className="footer">
          <p>© HAZEYJANEFILMS. 2023</p>
        </div>
      </header>
    </div>
  );
}

export default App;
